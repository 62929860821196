@import '../../styles/variables.less';

.right {
  right: 20px;
  left: unset;
  border-radius: 12px 12px 2px 12px;
}

.left {
  right: unset;
  left: 20px;
  border-radius: 12px 12px 12px 2px;
}

.wrapper {
  z-index: 0;
  pointer-events: none;
  position: fixed;
  bottom: 105px;
  width: 500px;
  height: 600px;
  background-color: var(--widget-messenger-background-color);
  opacity: 0;
  overflow: hidden;
  display: none;
  flex-direction: column;
  box-shadow: 1px 4px 15px 0 rgba(0, 0, 0, 0.24);
  max-height: calc(100vh - 105px);

  @media only screen and (max-width: @mobile-max-width) {
    left: 0;
    right: 0;
    width: min(100vw, 500px);
    height: 100%;
    bottom: 0;
    border-radius: 0;
    max-height: 100vh;
  }
}

.opened {
  z-index: var(--widget-z-index);
  pointer-events: all;
  opacity: 1;
  display: flex;
}
