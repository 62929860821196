@import './styles/variables.less';

:root {
  --widget-z-index: 999999;
  --widget-messenger-background-color: #e5e5e5;
}
.grecaptcha-badge {
  visibility: hidden;
}

@media only screen and (max-width: @mobile-max-width) {
  .no-scroll-by-cresta-widget {
    overflow: hidden;
  }
}
