@import '../../styles/variables.less';

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.image {
  position: absolute;
  transition: all 0.5s;
  color: white;
  top: 11px;
  left: 11px;
  &__closeImage {
    top: 16px;
    left: 16px;
  }
}

.chatbotButton {
  z-index: 99999;
  position: fixed;
  bottom: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 1px 4px 15px 0;
  cursor: pointer;
  background-color: var(--df-messenger-minimized-chat-close-icon-color, @ai-message-text-color);
  transition: all 0.3s;

  &__customButton {
    box-shadow: none;
    background-color: transparent;
  }

  &.right {
    right: 20px;
    left: unset;
    &:after {
      right: -5px;
    }
    img {
      right: 1px;
      left: unset;
    }
  }

  &.left {
    left: 20px;
    right: unset;
    &:after {
      right: 40px;
    }
    img {
        left: 1px;
        right: unset;
    }
  }

  &.firstTime {
    &:after {
      content: "1";
      display: flex;
      position: absolute;
      top: -5px;
      width: 20px; /* size of the notification icon */
      height: 20px; /* size of the notification icon */
      border-radius: 50%;
      background-color: red;
      color: white;
      font-size: 12px;
      font-weight: bold;
      align-items: center;
      justify-content: center;
    }
  }
}
.rotate {
  transform: rotate(90deg);
}

.defaultPopup {
  --message-background-color: #e4e9ed;
  --message-text-color: black;
  --width: 200px;
  --from-bottom: 70px;
  transition: all 0.3s;
  display: none;
  opacity: 0;
}
.left {
  --from-left: 70px;
  --fromr-right: auto;
}
.right {
  --from-left: auto;
  --fromr-right: 70px;
}
.crestaPopupContainer {
  display: none;
}
.show {
  display: block;
  animation: fadeIn 1s forwards;
}
.all {
  display: none;
}
.mobile {
  @media (max-width: @mobile-max-width) {
    display: none;
  }
}
.desktop {
  @media (min-width: @desktop-min-width) {
    display: none;
  }
}
.hide {
  display: none;
}
.customIcon {
  height: 56px;
  position: absolute;
  top: 1px;
  opacity: 0;
  transition: all 0.3s;
}
.hideImage {
  opacity: 0;
}
.showImage {
  opacity: 1;
}
